var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-tabs",style:(_vm.style)},_vm._l((_vm.tabs),function(tab,idx){return _c('div',{key:idx,class:[
      'tab',
      {
        active: _vm.activeTab === idx,
        no_underline: 'underline' in _vm.options && !_vm.options.underline,
      } ],style:({ textTransform: _vm.options.textTransform }),on:{"click":function($event){tab.action
        ? [tab.action({ tab: tab }), (_vm.activeTab = idx)]
        : (_vm.activeTab = idx)}}},[(typeof tab === 'string')?[_vm._v(" "+_vm._s(tab)+" ")]:(tab.label)?[_vm._v(" "+_vm._s(tab.label)+" ")]:_vm._e(),(_vm.options.separator && idx < Object.keys(_vm.tabs).length - 1)?_c('span',{staticClass:"tabs-separator"},[_vm._v("/")]):_vm._e()],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }