<template>
  <div class="login-container">
    <SystemNotifications />
    <div class="user-form-wrapper">
      <div class="logo-container">
        <a href="/">
          <img
            :src="require('@/assets/logo-mobile-header.svg')"
            alt="Logo"
            class="sidebar__top__logo"
          />
        </a>
      </div>
      <LabelTabs
        :tabs="tabList"
        :options="tabOptions"
        class="logon-tabs basic-mb"
      />
      <router-view name="login" />
      <router-view name="register" />
    </div>
  </div>
</template>

<script>
import LabelTabs from "@/components/Tabs/LabelTabs.vue";
import SystemNotifications from "@/components/Helpers/SystemNotifications";
const colors = require("/colors.config.json");
import { mapGetters } from "vuex";

export default {
  components: {
    LabelTabs,
    SystemNotifications,
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    tabList() {
      return [
        {
          label: this.labels.login,
          action: () => this.goToRoute({ name: "login" }),
        },
        {
          label: this.labels.register,
          action: () => this.goToRoute({ name: "register" }),
        },
      ];
    },
    tabOptions() {
      return {
        color: "#292929",
        activeColor: `${colors.navy}`,
        separator: true,
        defaultActive: this.$route.name === "signin" ? 0 : 1,
        underline: false,
        textTransform: "uppercase",
        fontSize: "1rem",
      };
    },
  },
  methods: {
    goToRoute(route) {
      if (this.$route.name !== route.name) this.$router.push(route);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/multi-component/user-logon";
</style>
