<template>
  <div class="label-tabs" :style="style">
    <div
      :class="[
        'tab',
        {
          active: activeTab === idx,
          no_underline: 'underline' in options && !options.underline,
        },
      ]"
      :style="{ textTransform: options.textTransform }"
      v-for="(tab, idx) in tabs"
      :key="idx"
      @click="
        tab.action
          ? [tab.action({ tab: tab }), (activeTab = idx)]
          : (activeTab = idx)
      "
    >
      <template v-if="typeof tab === 'string'">
        {{ tab }}
      </template>

      <template v-else-if="tab.label">
        {{ tab.label }}
      </template>

      <span
        class="tabs-separator"
        v-if="options.separator && idx < Object.keys(tabs).length - 1"
        >/</span
      >
    </div>
  </div>
</template>

<script>
const colors = require("/colors.config.json");
export default {
  name: "LabelTabs",
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    style() {
      let output = {
        "--color": `${colors.light_text}`,
        "--active-color": `${colors.purple}`,
        "--font-weight": "500",
        "--font-size": ".875rem",
        "--padding": "5px 10px",
      };
      if (this.options.color) output["--color"] = this.options.color;
      if (this.options.activeColor)
        output["--active-color"] = this.options.activeColor;
      if (this.options.fontWeight)
        output["--font-weight"] = this.options.fontWeight;
      if (this.options.fontSize) output["--font-size"] = this.options.fontSize;
      if (this.options.padding) output["--padding"] = this.options.fontWeight;
      return output;
    },
  },
  mounted() {
    if (this.options?.defaultActive)
      this.activeTab = this.options.defaultActive;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.label-tabs {
  display: flex;
  flex-wrap: wrap;

  .tab {
    color: var(--color);
    font-weight: var(--font-weight);
    font-size: var(--font-size);
    padding: var(--padding);
    cursor: pointer;
    border-bottom: 2px solid transparent;
    position: relative;
    @include transition--ease-out;

    &:hover,
    &.active {
      border-color: var(--active-color);
      color: var(--active-color);
    }

    &.no_underline {
      border-color: transparent;

      &:hover,
      &.active {
        border-color: transparent;
      }
    }

    .tabs-separator {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
      color: var(--color);

      &:hover,
      &.active {
        color: var(--color);
      }
    }
  }
}
</style>
